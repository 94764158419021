.container {
    .subpage {
        height: calc(100vh - 200px);
        .content {
            padding-top: 200px;
            h1 {
                text-align: center;
                margin-bottom: 50px;
            }
            p {
                max-width: 600px;
            }
        }
    }
}