.box {
    width: 250px;
    height: 300px;
    h1 {
        width: 100%;
        text-align: center;
        height: 10%;
        font-size: 30px;
        margin-bottom: 20px;
    }
    .image {
        width: 100%;
        height: 85%;
        background-size: cover;
        background-position: center;
    }
}