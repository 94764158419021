.container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
}

h1, h2, p, a {
    color: black;
    transition: color .5s ease-in-out;
    font-family: Garamond, sans-serif;
}

p {
    max-width: 940px;
    text-align: justify;
}

a {
    &:hover {
        text-decoration: none;
        h1, h2 {
            color: #9ecdff;
        }
    }
}