#footer {
    margin: 100px 0 0 0;
    height: 150px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #3f94f7;
    .container {
        .content {
            display: flex;
            flex-direction: row;
            width: 100%;
            justify-content: space-between;
            max-width: 940px;
            ul {
                list-style: none;
                margin: 0;
                padding: 0;
                display: inline-block;
                li {
                    color: white;
                    a {
                        color: white;
                        &:hover {
                            color: #9ecdff;
                        }
                    }
                }
            }
        }
    }
}