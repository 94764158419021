.NavBar {
    height: 100px;
    width: 100%;    
    background: #3f94f7;
    display: flex;
    flex-direction: row;
    align-items: center;
    position: fixed;
    top: 0;
    .container {
        width: 100%;
    }
    .logo {
        h1 {
            width: fit-content;
            padding-bottom: 3px;
            margin-bottom: 0;
            color: white;
            &:hover {
                color: #9ecdff;
            }
        }
    }
    .menu {
        display: flex;
        flex-direction: row;
        justify-content: space-evenly;
        h2 {
            font-size: 25px;
            margin-bottom: 0;
            color: white;
            &:hover {
                color: #9ecdff;
            }
        }
    }
    @media(max-width: 768px) {
        .container {
            .menu {
                display: none;
            }
        }
    }
}