.presentation {
    padding: 200px 0 100px 0;
    .container {
        flex-direction: column;
        .content {
            p {
                margin-bottom: 0;
            }
        }
    }
}